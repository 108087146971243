import React from 'react'

const Register = () => {
  return (
    <div class="mt-5 pt-5">
      register
    </div>
  )
}

export default Register
