import img1 from "./../Assets/c2 (1).webp";
import img2 from "./../Assets/c2.webp";
import img3 from "./../Assets/c3 (1).webp";
import img4 from "./../Assets/c3 (2).webp";
import img5 from "./../Assets/c3.webp";
import img6 from "./../Assets/c9 (1).webp";
import img7 from "./../Assets/c9 (2).webp";
import img8 from "./../Assets/c9.webp";
import img9 from "./../Assets/c2 (1).webp";


export const data = [
  {
    id: 1,
    name: "Fresh Menthol Mojito Vape Bar",
    image: img1,
    price: "£4.32",
  },
  {
    id: 2,
    name: "Sour Apple Blueberry Vape Bar",
    image: img2,
    price: "£4.32",
  },
  {
    id: 3,
    name: "Vimbull Ice Vape Bar",
    image: img3,
    price: "£4.32",
  },
  {
    id: 4,
    name: "Pink Lemonable Vape Bar",
    image:img4,
    price: "£4.32",
  },
  {
    id: 5,
    name: "Blueberry Raspberry Vape Bar",
    image:img5,
    price: "£4.32",
  },
  {
    id: 6,
    name: "Strawberry Kiwi Vape Bar",
    image:img6,
    price: "£4.32",
  },
  {
    id: 7,
    name: "Cherry Ice SKE Bar",
    image:img7,
    price: "£4.32",
  },
  {
    id: 8,
    name: "Berry Ice Vape Bar",
    image:img8,
    price: "£4.32",
  },
  {
    id: 9,
    name: "Green Grape Vape Bar",
    image:img9,
    price: "£4.32",
  }];
