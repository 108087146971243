import React from 'react'

const Login = () => {
  return (
    <div class="mt-5 pt-5 fs-5">
      login
    </div>
  )
}

export default Login
